<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4 create-new'>
      <b-col cols="12">
        <i class="fa fa-backward"></i>
        <router-link :to="{name: SinglePath.name}"> Single Offers</router-link>
      </b-col>
      <b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
          <div class='card'>
            <div class='bg-success p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Create New Single Offer</h5>
            </div>
            <div class='card-body'>
              <b-row>
                <b-col lg='4'>

                  <b-form-group label='Item Type' label-for='item_type'>
                    <b-form-select id='item_type' v-model='form.item_type' :options='itemTypeSearchOptions'
                      @change="itemTypeSelected" required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Item'>
                    <multiselect label='name' track-by='id' placeholder='Type to Search' open-direction='bottom'
                      required='required' :searchable='true' :close-on-select='true' :options-limit='10'
                      :multiple='false' :allow-empty='false' v-model='form.item' @search-change='itemFind'
                      :loading='isItemSearch' :options='itemSearchOptions'>
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Status'>
                    <b-form-select id="offer_status" v-model="form.offer_status" :options="offerStatusOptions" required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg='4'>
                  <b-form-group label='Web Price USD'>
                    <b-form-input id='price' v-model='form.price_usd' type='number' step='any' required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Web Price IDR'>
                    <b-form-input id='price' v-model='form.price_idr' type='number' step='any' required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg='6'>
                  <b-form-group label='IOS Tier'>
                    <multiselect v-model='form.ios_tier' label='tier_code' track-by='tier_code' placeholder='Ios Tier'
                      open-direction='bottom' :options='iosTierSearchOptions' :searchable='true'
                      :loading='isIosTierSearch' :close-on-select='true' :multiple='false' @search-change='iosTierFind'>
                    </multiselect>
                    <dfn>
                      USD : {{ form.ios_tier ? form.ios_tier.tier_price : 0 }}
                      <br>
                      IDR : {{ form.ios_tier ? form.ios_tier.price_idr : 0 | toCurrencyID }}
                    </dfn>
                  </b-form-group>
                </b-col>

                <b-col lg='6'>
                  <b-form-group label='Android Tier'>
                    <multiselect v-model='form.android_tier' label='tier_code' track-by='tier_code'
                      placeholder='Ios Tier' open-direction='bottom' :options='androidTierSearchOptions'
                      :searchable='true' :loading='isAndroidTierSearch' :close-on-select='true' :multiple='false'
                      @search-change='androidTierFind'>
                    </multiselect>
                    <dfn>
                      USD : {{ form.android_tier ? form.android_tier.tier_price : 0 }}
                      <br>
                      IDR : {{ form.android_tier ? form.android_tier.price_idr : 0 | toCurrencyID }}
                    </dfn>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <b-form-checkbox v-model='form.is_free' :value=true unchecked-value='false'> Free Offer
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button variant='primary' type='submit'>create</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SinglePath } from '../../router/marketing';
import constant from '../../store/constant';

delete constant.ITEM_TYPES.NEWSPAPER;

export default {
  name: 'newSingleOffer',
  data() {
    return {
      SinglePath,
      debounce: null,
      form: {
        item_type: null,
        item: null,
        price_usd: 0,
        price_idr: 0,
        ios_tier: null,
        android_tier: null,
        is_free: false,
        offer_status: null,
      },
      itemTypeSearchOptions: [
        { text: 'Select Type', value: null },
        ...Object.values(constant.ITEM_TYPES),
      ],
      offerStatusOptions: [
        { value: null, text: 'Select Status'},
        { value: constant.OFFER_STATUS.READY_FOR_SALE, text: 'On Sale' },
        { value: constant.OFFER_STATUS.NOT_FOR_SALE, text: 'Not Sale' },
      ],
      isItemSearch: false,
      itemSearchOptions: [],
      isIosTierSearch: false,
      iosTierSearchOptions: [],
      isAndroidTierSearch: false,
      androidTierSearchOptions: [],
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.offers.isError,
      isLoading: (state) => state.offers.isLoading,
      errorMessage: (state) => state.offers.errorMessage,
      successMessage: (state) => state.offers.successMessage,
      item: (state) => state.offers.item,
    }),
  },
  watch: {
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      
      // reset form
      Object.assign(this.$data, this.$options.data.call(this));
    },
  },
  created() {
  },
  methods: {
    ...mapActions('items', ['searchItemByType']),
    ...mapActions('offers', ['postSingleOffers']),
    ...mapActions('tiercodeAndroid', {
      searchAndroidTier: 'searchAndroidTier',
    }),
    ...mapActions('tiercodeIOS', {
      searchIosTier: 'searchIosTier',
    }),
    onSubmit(event) {
      event.preventDefault();
      const data = this.form;
      if (!data.android_tier?.id) {
        this.messageAlert('error', 'android tier not selected!', 5000);
        return;
      }
      if (!data.ios_tier?.id) {
        this.messageAlert('error', 'IOS tier not selected!', 5000);
        return;
      }
      const payload = {
        name: data.item.name,
        item_id: data.item.id,
        android_tier_id: data.android_tier.id,
        ios_tier_id: data.ios_tier.id,
        offer_status: data.offer_status,
        price_usd: data.price_usd,
        price_idr: data.price_idr,
        is_active: true,
        is_free: data.is_free,
        offer_type_id: constant.OFFER_TYPE.SINGLE,
        offer_code: data.item.edition_code,
        item_code: data.item.edition_code,
      };
      this.postSingleOffers(payload);
    },

    onReset() { },

    itemFind(query) {
      if (!query) return;
      if (!this.form.item_type) return;

      this.isItemSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        const payload = {
          item_type: this.form.item_type?.toLowerCase(),
          q: query,
        };
        this.searchItemByType(payload).then((response) => {
          this.itemSearchOptions = response.data.data.rows;
          this.isItemSearch = false;
        }).catch(() => {
          this.isItemSearch = false;

        });
      }, 600);
    },

    itemTypeSelected() {
      this.itemSearchOptions = [];
    },

    iosTierFind(query) {
      if (!query) return;

      this.isIosTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchIosTier({ q: query }).then((response) => {
          this.iosTierSearchOptions = response.data.data.rows;
          this.isIosTierSearch = false;
        }).catch(() => {
          this.isIosTierSearch = false;
        });
      }, 600);
    },

    androidTierFind(query) {
      if (!query) return;

      this.isAndroidTierSearch = true;
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.searchAndroidTier({ q: query }).then((response) => {
          this.androidTierSearchOptions = response.data.data.rows;
          this.isAndroidTierSearch = false;
        }).catch(() => {
          this.isAndroidTierSearch = false;
        });
      }, 600);
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
  filters: {
    toCurrencyID: function (val) {
      return parseInt(val).toLocaleString('id');
    }
  }
};
</script>
